import React from 'react';
import PropTypes from 'prop-types';
import './Header.css';
import MobileNav from '../MobileNav/MobileNav';
import { Link, NavLink } from 'react-router-dom';
import Navlinks from '../Navlinks/Navlinks';

const Header = () => (
  <div className="Header flex justify-between items-center  shadow-sm fixed top-0 left-0 w-full bg-white z-50 px-6 py-2" data-testid="Header">
      <NavLink to='/'>
      <img alt='logo' src="/logo.png" className=' w-24  md:w-26'></img>
      </NavLink>
    <div className='left-nav flex gap-4'>

      <Navlinks></Navlinks>
      <a href='https://maskaniadmin-production-9d4a.up.railway.app/' className=' hidden lg:flex gap-4 max-h-min self-center'>
      <div className=' hidden lg:flex gap-4 max-h-min self-center'>
        <button
          type="button"
          class="inline-block whitespace-nowrap overflow-ellipsis rounded-full text-white bg-pri tx px-8 pb-3 pt-3.5 text-xs font-medium uppercase leading-normal transition duration-150 ease-in-out  focus:bg-primary-accent-100 focus:outline-none focus:ring-0 active:bg-primary-accent-200">
          Sell with Us
        </button>
      </div>
      </a>
      <div className='lg:hidden'>
        <MobileNav>
        </MobileNav>
      </div>
    </div>
  </div>
);

Header.propTypes = {};

Header.defaultProps = {};

export default Header;
