import React from "react";
import PropTypes from "prop-types";
import "./ServiceCardLayout.css";
import Website from "./Assets/web.png";
import Buyer from "./Assets/buyer.png";
import Seller from "./Assets/seller.png";
import ServiceCard from "../ServiceCard/ServiceCard";

const AboutUsCard = () => {
  return (
    <div
      className="ServiceCardLayout grid md:flex gap-2 lg:gap-8 justify-center relative mt-8 "
      data-testid="AboutUsCard"
    >
      <ServiceCard
        title="Seller"
        image={Seller}
        features={[
          "Free Advertising",
          "Realtime Feedback",
          "Listing & Management",
          "Social Interactions",
        ]}
      />
      <ServiceCard
        title="Buyer"
        image={Buyer}
        features={[
          "Free Advertising",
          "Realtime Feedback",
          "Listing & Management",
          "Social Interactions",
        ]}
      />
      <ServiceCard
        title="Website"
        image={Website}
        features={[
          "Free Advertising",
          "Realtime Feedback",
          "Listing & Management",
          "Social Interactions",
        ]}
      />
    </div>
  );
};

AboutUsCard.propTypes = {};

AboutUsCard.defaultProps = {};

export default AboutUsCard;
