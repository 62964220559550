import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './ClassifiedPagination.css';
import ClassifiedCard from '../ClassifiedCard/ClassifiedCard';
import Pagination from '../Pagination/Pagination';
import ClassifiedsLayout from '../../layout/ClassifiedsLayout/ClassifiedsLayout';

const ClassifiedPagination = ({ items }) => {

 



  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 32;
  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = items.slice(startIndex, endIndex);


  return (
    <>
      <ClassifiedsLayout>

        {currentItems.map((item) => (
          <ClassifiedCard key={item.id} classified={item}></ClassifiedCard>
        ))}

      </ClassifiedsLayout>
      <div>

        <Pagination items={items} handlePageChange={handlePageChange} itemsPerPage={32}></Pagination>
      </div>
    </>
  );
}

ClassifiedPagination.propTypes = {};

ClassifiedPagination.defaultProps = {};

export default ClassifiedPagination;
