import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './MarketPage.css';
import ClassifiedCard from '../../components/ClassifiedCard/ClassifiedCard';
import MaCrketCard from '../../components/MaCrketCard/MaCrketCard';
import DefaultLayout from '../../layout/DefaultLayout/DefaultLayout';
import PropertiesLayout from '../../layout/PropertiesLayout/PropertiesLayout';
import SearchBar from '../../components/SearchBar/SearchBar';
import TitleText from '../../components/TitleText/TitleText';
import MarketPagination from '../../components/MarketPagination/MarketPagination';
import { BASE_URL } from '../../constants';

const MarketPage = () => {


  const [products, setProducts] = useState(null)




  useEffect(() => {
    fetch(`${BASE_URL}/products`)
      // fetch(`http://127.0.0.1:3002/api/products`)
      .then(response => response.json())
      .then(json => setProducts(json))
      .catch(err => console.log(err))

  }, [])

  const loading = (products) => {
    if (products) {
      return false
    } else {
      return true
    }
  }

  const search = (searchParams) => {
    setProducts(null)
    fetch(`${BASE_URL}/products/search?q=${searchParams}&category=''`)
      .then(response => response.json())
      .then(json => setProducts(json))
      .catch(err => console.log(err))
  }










  return (
    <DefaultLayout loading={loading(products)}>

      <SearchBar handleSearch={search}></SearchBar>
      <TitleText>Markets</TitleText>
      {products &&
        <MarketPagination items={products}></MarketPagination>
      }


    </DefaultLayout>
  );
}

MarketPage.propTypes = {};

MarketPage.defaultProps = {};

export default MarketPage;
