import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './PropertyViewPage.css';
import DefaultLayout from '../../layout/DefaultLayout/DefaultLayout';
import NextImageButton from '../../components/NextImageButton/NextImageButton';
import ImageSelector from '../../components/ImageSelector/ImageSelector';
import TitleText from '../../components/TitleText/TitleText';
import PropertyDetailsCard from '../../components/PropertyDetailsCard/PropertyDetailsCard';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Corousel from '../../components/Corousel/Corousel';
import DOMPurify from 'dompurify'
import { BASE_URL } from '../../constants';
const PropertyViewPage = () => {

  const navigate = useNavigate();

  const { state } = useLocation();
  const params = useParams();


  const [property, setProperty] = useState(null)




  useEffect(() => {
    fetch(`${BASE_URL}/properties/${params.id}`)
      .then(response => response.json())
      .then(json => setProperty(json))
      .catch(err => console.log(err))

  }, [])

  const loading = (property) => {
    if (property != null) {
      return false
    } else {
      return true
    }
  }



  const icon1 = <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.875 12.5C8.59805 12.5 10 11.098 10 9.375C10 7.65195 8.59805 6.25 6.875 6.25C5.15195 6.25 3.75 7.65195 3.75 9.375C3.75 11.098 5.15195 12.5 6.875 12.5ZM20.625 7.5H11.875C11.5297 7.5 11.25 7.77969 11.25 8.125V13.75H2.5V5.625C2.5 5.27969 2.22031 5 1.875 5H0.625C0.279687 5 0 5.27969 0 5.625V19.375C0 19.7203 0.279687 20 0.625 20H1.875C2.22031 20 2.5 19.7203 2.5 19.375V17.5H22.5V19.375C22.5 19.7203 22.7797 20 23.125 20H24.375C24.7203 20 25 19.7203 25 19.375V11.875C25 9.45859 23.0414 7.5 20.625 7.5Z" fill="#2F80ED" />
  </svg>


  return (
    <DefaultLayout loading={loading(property)}>
      <div className='Image flex flex-col gap-6'>
        {/* <img className='rounded max-h-[600px] object-cover' src={state.property.image_urls[0]} alt='property'></img> */}

        {property && (
          <>
            <Corousel images={property.image_urls}></Corousel>

            <TitleText>{property.title}</TitleText>

            <div className='PropertyType px-4 py-5 bg-pri rounded-md flex justify-between'>
              <p className='text-white font-bold self-center items-center capitalize'>{property.category}</p>
              <div className='flex gap-2 items-baseline'>
                <p className='text-white text-xs'>KSh</p>
                <p className='text-white text-3xl font-bold'>{property.price}</p>
              </div>
            </div>

            <TitleText>Details</TitleText>
            <div className='grid grid-cols-1 gap-2 md:grid-cols-2'>
              <PropertyDetailsCard icon={icon1} label={"Bedrooms"} text={property.rooms} />
              <PropertyDetailsCard icon={icon1} label={"Bathrooms"} text={property.bathrooms} />
              <PropertyDetailsCard icon={icon1} label={"Size"} text={property.size} />
              <PropertyDetailsCard icon={icon1} label={property.location} />
            </div>

            <TitleText>Description</TitleText>

            <div className='text-xl text-default-color' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(property.desc) }}>
            </div>
          </>
        )
        }


      </div>

    </DefaultLayout>
  );
}

PropertyViewPage.propTypes = {};

PropertyViewPage.defaultProps = {};

export default PropertyViewPage;
