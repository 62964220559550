import React from 'react';
import PropTypes from 'prop-types';
import './FAQLayout.css';
import Header from '../../components/Header/Header';
import MobileNav from '../../components/MobileNav/MobileNav';
import Footer from '../../components/Footer/Footer';


const FaqLayout = ({ children }) => (
  <div>
    <div className="App px-6 py-8 md:px-8 md:py-9 w-full lg:w-11/12 xl:w-10/12 mx-auto flex flex-col gap-4">
      <Header></Header>
      <MobileNav></MobileNav>
    </div>
    <div className='Banner py-10 bg-pri flex flex-col text-white'>
      <div className='md:px-8 md:py-9 w-full lg:w-11/12 xl:w-10/12 mx-auto flex flex-col gap-4 items-center justify-center'>
        <p className=' text-center text-white font-bold text-2xl'>Frequently Asked Questions </p>
        <p className='text-center text-xl w-1/2 md:w-1/4'>Commonly asked questions by our users if you have any other, feel free to reach out</p>
      </div>

    </div>
    <div className="App px-6 py-8 md:px-8 md:py-9 w-full  lg:w-11/12 xl:w-10/12 mx-auto flex flex-col gap-4">
      {children}
    </div>
    <Footer></Footer>
  </div>
);

FaqLayout.propTypes = {};

FaqLayout.defaultProps = {};

export default FaqLayout;
