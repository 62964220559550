import React from 'react';
import PropTypes from 'prop-types';
import './Banner.css';

const Banner = () => (
  <div className="Banner my-6" data-testid="Banner">
    <img src='/banner.png' alt='banner' className='w-full'></img>
  </div>
);

Banner.propTypes = {};

Banner.defaultProps = {};

export default Banner;
