import React from 'react';
import PropTypes from 'prop-types';
import './Navlinks.css';
import { Link } from 'react-router-dom';
import DesktopNavLink from '../DesktopNavLink/DesktopNavLink';



const Navlinks = () => (
  <nav className="hidden lg:flex">
  <div className="mx-auto px-4 max-w-7xl sm:px-6 lg:px-8">
    <div className="flex items-center justify-between h-16">
      <div className="flex items-center text-xl gap-4">


        <DesktopNavLink text="Home" path="/"></DesktopNavLink>

        <DesktopNavLink text="Properties" path="/properties"></DesktopNavLink>

        <DesktopNavLink text="Classifieds" path="/classifieds"></DesktopNavLink>

        <DesktopNavLink text="Market" path="/markets"></DesktopNavLink>


        {/* <DesktopNavLink text="About" path="/about"></DesktopNavLink> */}

        {/* <DesktopNavLink text="Contact" path="/contact"></DesktopNavLink> */}

        

      </div>
     
    </div>
  </div>
</nav>

);

Navlinks.propTypes = {};

Navlinks.defaultProps = {};

export default Navlinks;
