import React from 'react';
import PropTypes from 'prop-types';
import './FAQPage.css';
import FaqLayout from '../../layout/FAQLayout/FAQLayout';
import FaqCard from '../../components/FAQCard/FAQCard';

const FaqPage = () => {
  const list = [
    {
      title:"Can i list my property here?",
      body:"- Yes, just sign up. \n - Go to your dashboard \n  - Upload images and short description \n - Click post to list your property."
    },
    {
      title:"How do i know what to list my house for",
      body:"- It varies case by case \n - Use current market rates \n - Consider utilities and features included."
    }
  ]

  const faqs = list.map((faq) => {
    return <FaqCard title={faq.title} body={faq.body}></FaqCard>
  })

  return (
    <div className='my-8'>
      <FaqLayout>

        {faqs}

      </FaqLayout>
    </div>
  );

}
FaqPage.propTypes = {};

FaqPage.defaultProps = {};

export default FaqPage;
