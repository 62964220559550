import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './FAQCard.css';

const FaqCard = ({ title, body }) => {
  
  
 const [active,setActive] = useState()
 
 
 const toggle = () =>{
  setActive(!active)
 } 
  
  
  
  
  return(
  <div className='rounded border border-pri py-3 px-4  flex flex-col gap-4 transition '>
    <div className='flex justify-between' onClick={toggle}>
      <p className='flex-1 font-bold capitalize'>
        {title}
      </p>

      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 5V19" stroke="#2F80ED" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M5 12H19" stroke="#2F80ED" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
    </div>

    {active &&
          <div className='content bg-[#f1f1f1] p-2 rounded-sm'>
          <p className=' text-gray-400 whitespace-pre-line'>
            {body}
          </p>
        </div>
    }


  </div>
);
}

FaqCard.propTypes = {};

FaqCard.defaultProps = {};

export default FaqCard;
