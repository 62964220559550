import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './PropertyPagination.css';
import PropertyCard from '../PropertyCard/PropertyCard';
import Pagination from '../Pagination/Pagination';
import ClassifiedCard from '../ClassifiedCard/ClassifiedCard';
import PropertiesLayout from '../../layout/PropertiesLayout/PropertiesLayout';

const PropertyPagination = ({ items }) => {

  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 32;
  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = items.slice(startIndex, endIndex);


  return (
    <div>
      <PropertiesLayout>
        {currentItems.map((item) => (
          <PropertyCard key={item.id} property={item}></PropertyCard>
        ))}

      </PropertiesLayout>


      <Pagination items={items} handlePageChange={handlePageChange} itemsPerPage={32}></Pagination>
    </div>
  );
}

PropertyPagination.propTypes = {};

PropertyPagination.defaultProps = {};

export default PropertyPagination;
