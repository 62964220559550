import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './SearchBar.css';

const SearchBar = ({handleSearch}) =>{ 
  
  const [searchParams,setSearchParams] = useState()
  
  const onClick = ()=>{
    handleSearch(searchParams)
  }
  
  
  return(
  <div className="SearchBar bg-pri my-6 py-8 flex w-full rounded px-4" data-testid="SearchBar">
    
    <div className='flex w-full bg-pri'>


      <div class="relative w-full h-full" data-te-input-wrapper-init>
        <input
          type="text"
          onChange={(e)=>setSearchParams(e.target.value)}
          value={searchParams}
          className="bg-white peer block min-h-full w-full rounded-l border-0 bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 peer-focus:text-primary data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:text-neutral-200 dark:placeholder:text-neutral-200 dark:peer-focus:text-primary [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0"
          id="exampleFormControlInput1"
          placeholder="Example label" />
        <label
          for="exampleFormControlInput1"
          class="pointer-events-none absolute left-3 top-0 mb-0 max-w-[90%] origin-[0_0] truncate pt-[0.37rem] leading-[1.6] text-neutral-500 transition-all duration-200 ease-out peer-focus:-translate-y-[0.9rem] peer-focus:scale-[0.8] peer-focus:text-primary peer-data-[te-input-state-active]:-translate-y-[0.9rem] peer-data-[te-input-state-active]:scale-[0.8] motion-reduce:transition-none dark:text-neutral-200 dark:peer-focus:text-primary"
        >Search Property
        </label>
      </div>

      <div className='icon bg-sec flex items-center justify-center px-2 rounded-r' onClick={onClick}>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M21 21L16.65 16.65" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </div>

    </div>
  </div>
)
}
;

SearchBar.propTypes = {};

SearchBar.defaultProps = {};

export default SearchBar;
