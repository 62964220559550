import React from "react";

const Input = ({ placeholder, type, name, id }) => {
  return (
    <div className=" ">
      <input
        type={type}
        placeholder={placeholder}
        name={name}
        id={id}
        className="w-full my-2 py-2 px-2 rounded"
      />
    </div>
  );
};

export default Input;
