import React from 'react';
import PropTypes from 'prop-types';
import './DefaultLayout.css';

import Footer from '../../components/Footer/Footer.js';
import MobileNav from '../../components/MobileNav/MobileNav';
import Header from '../../components/Header/Header';
import { TailSpin } from 'react-loader-spinner';
import FadeIn from 'react-fade-in/lib/FadeIn.js';

const DefaultLayout = ({ children, loading }) => (

  <div className=' flex flex-col'>
    <div className="App min-h-screen  px-6 py-8 md:px-8 md:py-9 flex-1  w-full lg:w-[90%] xl:w-[80%] mx-auto flex flex-col gap-4 pt-16 md:pt-20  transition-opacity opacity-0 opacity-100 duration-300">

      {/* <MobileNav></MobileNav> */}

      {!loading
        ? <><Header></Header>
          <FadeIn>
            {children}
           
          </FadeIn>
        </>
        : (
          <div className='flex flex-1  flex-col gap-2 h-full justify-center items-center justify-self-center'>
            <TailSpin
              height="50"
              width="50"
              color="#2F80ED"
              ariaLabel="tail-spin-loading"
              radius="2"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true} />
              <p className='text-gray-400 mt-5 '>loading</p>
          </div>
        )
      }
    </div>
    <Footer></Footer>
  </div>

);

DefaultLayout.propTypes = {};

DefaultLayout.defaultProps = {};

export default DefaultLayout;
