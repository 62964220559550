import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './ClassifiedsPage.css';
import DefaultLayout from '../../layout/DefaultLayout/DefaultLayout';
import ClassifiedsLayout from '../../layout/ClassifiedsLayout/ClassifiedsLayout';
import SearchBar from '../../components/SearchBar/SearchBar';
import ClassifiedCard from '../../components/ClassifiedCard/ClassifiedCard';
import TitleText from '../../components/TitleText/TitleText';
import ClassifiedPagination from '../../components/ClassifiedPagination/ClassifiedPagination';
import { BASE_URL } from '../../constants';

const ClassifiedsPage = () => {

  const [classifieds, setClassifieds] = useState(null)
  useEffect(() => {
    fetch(`${BASE_URL}/classifieds`)
      .then(response => response.json())
      .then(json => setClassifieds(json))
      .catch(err => console.log(err))

  }, [])



  const search = (searchParams) => {
    setClassifieds(null)
    fetch(`${BASE_URL}/classifieds/search?q=${searchParams}&category=''`)
      .then(response => response.json())
      .then(json => setClassifieds(json))
      .catch(err => console.log(err))
  }



  const loading = (classifieds) => {
    if (classifieds) {
      return false
    } else {
      return true
    }
  }


  return (
    <DefaultLayout loading={loading(classifieds)}>
      <SearchBar handleSearch={search}></SearchBar>
      <TitleText>Classifieds</TitleText>
      {classifieds &&
        <ClassifiedPagination items={classifieds}></ClassifiedPagination>
      }


    </DefaultLayout>
  );
}

ClassifiedsPage.propTypes = {};

ClassifiedsPage.defaultProps = {};

export default ClassifiedsPage;
