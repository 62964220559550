import React from 'react';
import PropTypes from 'prop-types';
import './MaCrketCard.css';
import { Link } from 'react-router-dom';
import CardTitle from '../CardTitle/CardTitle';
import DOMPurify from 'dompurify';

const MaCrketCard = ({ product }) => (
  <Link to={"/markets/"+product.id}  state={{ product: product }}>
    <div className="ClassifiedCard rounded shadow flex flex-col" data-testid="ClassifiedCard">
      {/* header */}

      <div className='Header w-full'>
      {product.image_urls || product.main_image_url ?
          <>
            {product.main_image_url ?
              <img className='w-full  rounded-t-md object-cover h-[180px]' src={product.main_image_thumbnail_url} alt="product"></img>
              :
              <img className='w-full  rounded-t-md object-cover h-[180px]' src={product.image_thumbnails_urls[0]} alt="product"></img>
            }
          </>
          :
          <div className='w-full  rounded-t-md object-cover h-[180px]' />
        }
        
      </div>

      {/* body */}
      <div className='divide-y flex flex-col'>


        <div className=' flex flex-col gap-2  p-4'>
          <CardTitle>{product.name}</CardTitle>
          <p className=' text-default-color  line-clamp-2 ' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(product.desc) }}>
            {/* {product.description} */}
          </p>

        </div>

        <div className='flex w-full  p-4 justify-between items-center'>
          <p className='text-2xl text-pri font-bold' ><span>KSH </span>{product.price}</p>
          <div>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M5 12H19" stroke="black" stroke-opacity="0.4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M12 5L19 12L12 19" stroke="black" stroke-opacity="0.4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>

          </div>
        </div>

      </div>

      <div>

      </div>
    </div>
  </Link>
);

MaCrketCard.propTypes = {};

MaCrketCard.defaultProps = {};

export default MaCrketCard;
