import React from 'react';
import PropTypes from 'prop-types';
import './ClassifiedCard.css';
import CardTitle from '../CardTitle/CardTitle';
import { Link } from 'react-router-dom';
import DOMPurify from 'dompurify';

const ClassifiedCard = ({ classified }) => (
  <Link to={"/classifieds/" + classified.id} state={{ classified: classified }}>
    <div className="ClassifiedCard rounded shadow flex flex-col" data-testid="ClassifiedCard">
      {/* header */}

      <div className='Header w-full'>
        {classified.image_urls || classified.main_image_url ?
          <>
            {classified.main_image_url ?
              <img className='w-full  rounded-t-md object-cover h-[180px]' src={classified.main_image_thumbnail_url} alt="product"></img>
              :
              <img className='w-full  rounded-t-md object-cover h-[180px]' src={classified.image_thumbnails_urls[0]} alt="product"></img>
            }
          </>
          :
          <div className='w-full  rounded-t-md object-cover h-[180px]' />
        }
      </div>

      {/* body */}

      <div className=' flex flex-col gap-2 p-4'>
        <CardTitle>{classified.title}</CardTitle>
        {classified.short_desc ?
          <p className=' text-default-color min-h-[100px] line-clamp-2 ' >
            {classified.short_desc}
          </p>
          :
          <p className=' text-default-color min-h-[100px] line-clamp-2 ' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(classified.desc) }}>
            {/* {classified.desc} */}
          </p>
        }


      </div>

      <div>

      </div>
    </div>
  </Link>
);

ClassifiedCard.propTypes = {};

ClassifiedCard.defaultProps = {};

export default ClassifiedCard;
