import React from 'react';
import PropTypes from 'prop-types';
import './PropertiesLayout.css';

const PropertiesLayout = ({children}) => (
  <div className='grid gap-4 auto grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 xl:gap-5 items-stretch'>
          {children}
        </div>
);

PropertiesLayout.propTypes = {};

PropertiesLayout.defaultProps = {};

export default PropertiesLayout;
