import React, { useState } from 'react';
import './MarketPagination.css';
import MarketLayout from '../../layout/MarketLayout/MarketLayout';
import MaCrketCard from '../MaCrketCard/MaCrketCard';
import Pagination from '../Pagination/Pagination';

const MarketPagination = ({items}) => {
  
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 32;
  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = items.slice(startIndex, endIndex);


  
  return(
    <div>
    <MarketLayout>
      {currentItems.map((item) => (
        <MaCrketCard key={item.id} product={item}></MaCrketCard>
      ))}

    </MarketLayout>


    <Pagination items={items} handlePageChange={handlePageChange} itemsPerPage={32}></Pagination>
  </div>

);
}

MarketPagination.propTypes = {};

MarketPagination.defaultProps = {};

export default MarketPagination;
