import React from 'react';
import PropTypes from 'prop-types';
import './DesktopNavLink.css';
import { NavLink } from 'react-router-dom';

const DesktopNavLink = ({ text, path }) => {

  return (
    <NavLink to={path} className={({ isActive }) => (isActive ? ' text-pri text-xl p-2 font-bold' : 'text-xl text-default-color')}>
      {text}
    </NavLink>
  );
}

NavLink.propTypes = {};

NavLink.defaultProps = {};

export default DesktopNavLink;
