import React from "react";
// import ContactCard from "../ContactForm/ContactCard";
import locationIcon from "./Assets/location.svg";
import emailIcon from "./Assets/email.svg";
import phoneIcon from "./Assets/phone.svg";
import websiteIcon from "./Assets/web.svg";

const ContactCard = ({ icon, title, text }) => {
  return (
    <div className="flex gap-4 items-center ">
      <div className="h-20 w-20 bg-pri_500 rounded">
        <img src={icon} alt="" className="rounded cover h-24 w-24" />
      </div>
      <div className="text-white self-end mb-2">
        <p>{title}</p>
        <p>{text}</p>
      </div>
    </div>
  );
};

const GetInTouch = () => {
  return (
    <div className=" mt-8">
      <h3
        className="font-bold my-2 px-4 lg:text-[#FFFFFFB2]"
      >
        Get In Touch
      </h3>

      <div className=" bg-[#1F223E] py-12 lg:py-4 px-4   rounded">
        <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-1">
          <ContactCard icon= {locationIcon} title="Nairobi" text="Nairobi, Kenya" />
          <ContactCard
            icon={emailIcon}
            title="Telephone"
            text={<a href="tel:+254729925044">+254 729-925-044</a>}
          />
          <ContactCard
            icon={phoneIcon}
            title="Email"
            text={<a href="mailto:">info@maskanikenya.co.ke</a>}
          />
          <ContactCard
            icon={websiteIcon}
            title="Web"
            text={
              <a href="https://maskanikenya.co.ke/contact#">
                www.maskanikenya.co.ke
              </a>
            }
          />
        </div>
      </div>
    </div>
  );
};

export default GetInTouch;
