import React from 'react';
import PropTypes from 'prop-types';
import './PropertyDetailsCard.css';

const PropertyDetailsCard = ({icon,label,text}) => (
  <div className="PropertyDetailsCard rounded px-4 py-8 bg-[#F046250D] flex gap-4" data-testid="PropertyDetailsCard">
    {icon}
    <p className=' text-default-color flex-1 text-xl'>{label}</p>
    <p className='text-2xl text-pri font-bold'>{text}</p>
  </div>
);

PropertyDetailsCard.propTypes = {};

PropertyDetailsCard.defaultProps = {};

export default PropertyDetailsCard;
