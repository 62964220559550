import React from 'react';
import PropTypes from 'prop-types';
import './NextImageButton.css';

const NextImageButton = ({ left = true }) => {


  if (left) {
    return (<div className="NextImageButton px-4 py-2 bg-[#D5E6FB]" data-testid="NextImageButton">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15 18L9 12L15 6" stroke="#2F80ED" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      </svg>

    </div>
    )
  } else {
    return (
      <div className="NextImageButton px-4 py-2 bg-[#D5E6FB]" data-testid="NextImageButton">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M9 18L15 12L9 6" stroke="#2F80ED" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>

      </div>
    );
  }

};

NextImageButton.propTypes = {};

NextImageButton.defaultProps = {};

export default NextImageButton;
