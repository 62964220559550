import React from 'react';
import PropTypes from 'prop-types';
import './Footer.css';
import GetInTouchFooterCard from '../GetInTouchFooterCard/GetInTouchFooterCard';
import ContactFooterForm from '../ContactFooterForm/ContactFooterForm';
import { NavLink } from 'react-router-dom';
import { SocialIcon } from 'react-social-icons';

const Footer = () => (
  <div className="Footer pt-20 bg-pri bg-footer bg-contain  bg-left-bottom bg-no-repeat "  data-testid="Footer">
    <div className=' px-6 py-20 md:px-8 md:py-9 w-full lg:w-11/12 xl:w-10/12 mx-auto grid grid-cols-2 gap-6 lg:grid-cols-3'>
      <GetInTouchFooterCard></GetInTouchFooterCard>
{/* bg-[#FFFFFF1A] */}
      <div className='flex flex-col gap-2 h-full justify-between   p-4  text-lg'>
        <div className='flex flex-col'>
          <p className='text-xl font-bold text-white'>Links</p>
          <NavLink to='properties' className='text-white flex gap-2 items-center'>
            Properties
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 8.66667V12.6667C12 13.0203 11.8595 13.3594 11.6095 13.6095C11.3594 13.8595 11.0203 14 10.6667 14H3.33333C2.97971 14 2.64057 13.8595 2.39052 13.6095C2.14048 13.3594 2 13.0203 2 12.6667V5.33333C2 4.97971 2.14048 4.64057 2.39052 4.39052C2.64057 4.14048 2.97971 4 3.33333 4H7.33333" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M10 2H14V6" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M6.66675 9.33333L14.0001 2" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>

          </NavLink>
          <NavLink to='/classifieds' className='text-white flex gap-2 items-center  text-lg'>
            Classifieds
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 8.66667V12.6667C12 13.0203 11.8595 13.3594 11.6095 13.6095C11.3594 13.8595 11.0203 14 10.6667 14H3.33333C2.97971 14 2.64057 13.8595 2.39052 13.6095C2.14048 13.3594 2 13.0203 2 12.6667V5.33333C2 4.97971 2.14048 4.64057 2.39052 4.39052C2.64057 4.14048 2.97971 4 3.33333 4H7.33333" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M10 2H14V6" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M6.66675 9.33333L14.0001 2" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>

          </NavLink>


          <NavLink to='/products' className='text-white flex gap-2 items-center text-lg'>
            Products
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 8.66667V12.6667C12 13.0203 11.8595 13.3594 11.6095 13.6095C11.3594 13.8595 11.0203 14 10.6667 14H3.33333C2.97971 14 2.64057 13.8595 2.39052 13.6095C2.14048 13.3594 2 13.0203 2 12.6667V5.33333C2 4.97971 2.14048 4.64057 2.39052 4.39052C2.64057 4.14048 2.97971 4 3.33333 4H7.33333" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M10 2H14V6" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M6.66675 9.33333L14.0001 2" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>

          </NavLink>

          <NavLink to='/about' className='text-white flex gap-2 items-center text-lg'>
            About
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 8.66667V12.6667C12 13.0203 11.8595 13.3594 11.6095 13.6095C11.3594 13.8595 11.0203 14 10.6667 14H3.33333C2.97971 14 2.64057 13.8595 2.39052 13.6095C2.14048 13.3594 2 13.0203 2 12.6667V5.33333C2 4.97971 2.14048 4.64057 2.39052 4.39052C2.64057 4.14048 2.97971 4 3.33333 4H7.33333" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M10 2H14V6" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M6.66675 9.33333L14.0001 2" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>

          </NavLink>



          <NavLink to='/contact' className='text-white flex gap-2 items-center text-lg'>
            Contact us
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 8.66667V12.6667C12 13.0203 11.8595 13.3594 11.6095 13.6095C11.3594 13.8595 11.0203 14 10.6667 14H3.33333C2.97971 14 2.64057 13.8595 2.39052 13.6095C2.14048 13.3594 2 13.0203 2 12.6667V5.33333C2 4.97971 2.14048 4.64057 2.39052 4.39052C2.64057 4.14048 2.97971 4 3.33333 4H7.33333" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M10 2H14V6" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M6.66675 9.33333L14.0001 2" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>

          </NavLink>

          <NavLink to='/faq' className='text-white flex gap-2 items-center text-lg'>
            FAQs
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 8.66667V12.6667C12 13.0203 11.8595 13.3594 11.6095 13.6095C11.3594 13.8595 11.0203 14 10.6667 14H3.33333C2.97971 14 2.64057 13.8595 2.39052 13.6095C2.14048 13.3594 2 13.0203 2 12.6667V5.33333C2 4.97971 2.14048 4.64057 2.39052 4.39052C2.64057 4.14048 2.97971 4 3.33333 4H7.33333" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M10 2H14V6" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M6.66675 9.33333L14.0001 2" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>

          </NavLink>
        </div>
      </div>

      {/* <div className='flex flex-col gap-2 h-full justify-between  bg-[#FFFFFF1A] p-4'>
        <div className='flex flex-col'>
          <p className='text-xl font-bold text-white'>Get in Touch</p>
          <NavLink to='contact' className='text-white flex gap-2 items-center'>
            contact us
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 8.66667V12.6667C12 13.0203 11.8595 13.3594 11.6095 13.6095C11.3594 13.8595 11.0203 14 10.6667 14H3.33333C2.97971 14 2.64057 13.8595 2.39052 13.6095C2.14048 13.3594 2 13.0203 2 12.6667V5.33333C2 4.97971 2.14048 4.64057 2.39052 4.39052C2.64057 4.14048 2.97971 4 3.33333 4H7.33333" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M10 2H14V6" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M6.66675 9.33333L14.0001 2" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>

          </NavLink>
          <NavLink to='about' className='text-white flex gap-2 items-center'>
            About
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 8.66667V12.6667C12 13.0203 11.8595 13.3594 11.6095 13.6095C11.3594 13.8595 11.0203 14 10.6667 14H3.33333C2.97971 14 2.64057 13.8595 2.39052 13.6095C2.14048 13.3594 2 13.0203 2 12.6667V5.33333C2 4.97971 2.14048 4.64057 2.39052 4.39052C2.64057 4.14048 2.97971 4 3.33333 4H7.33333" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M10 2H14V6" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M6.66675 9.33333L14.0001 2" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>

          </NavLink>


          <NavLink to='/faq' className='text-white flex gap-2 items-center'>
            FAQ Page
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 8.66667V12.6667C12 13.0203 11.8595 13.3594 11.6095 13.6095C11.3594 13.8595 11.0203 14 10.6667 14H3.33333C2.97971 14 2.64057 13.8595 2.39052 13.6095C2.14048 13.3594 2 13.0203 2 12.6667V5.33333C2 4.97971 2.14048 4.64057 2.39052 4.39052C2.64057 4.14048 2.97971 4 3.33333 4H7.33333" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M10 2H14V6" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M6.66675 9.33333L14.0001 2" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>

          </NavLink>
        </div>


      </div> */}

      {/* <ContactFooterForm></ContactFooterForm> */}
      <div className='flex gap-4 justify-self-end self-end w-full flex-row items-end  justify-items-end grid-flow-row'>
        <SocialIcon url='https://web.facebook.com/maskanikenya254'></SocialIcon>
        <SocialIcon url='https://www.instagram.com/maskanikenya254/'></SocialIcon>
        <SocialIcon url='https://twitter.com/maskanikenya254'></SocialIcon>
      </div>
    </div>
    {/* <img className='h-20' src='footer_image.svg'></img> */}

    <div className='flex flex-col px-6 py-8 justify-center items-center text-white mt-20 bg-transparent'>
      <p>Copyright © 2024 Maskani Kenya. All rights reserved.</p>
      <p> Developed by Wira</p>
    </div>
  </div>
);

Footer.propTypes = {};

Footer.defaultProps = {};

export default Footer;
