import React from "react";
import InputField from "./Input";



const Form = () => {
  return (
    <div className="rounded">
      <form className="bg-[#2F80ED33] grid pt-4 pb-8 rounded">
        <p className=" text-center text-[#00000099] ">Hello</p>
        <p className=" text-center text-[#00000099]">Leave us a Message</p>

        {/* Form inputs */}

        <div className="w-11/12 mx-auto">
          <InputField
            placeholder="Name"
            name="name"
            id="name-input"
            type="text"
          />
          <InputField
            placeholder="Phone"
            type="tel"
            name="phone-input"
            id="name-input"
          />
          <InputField
            placeholder="Email"
            type="mail"
            name="name"
            id="email-input"
          />

          <textarea
            name="message"
            id="message"
            cols="30"
            rows="10"
            placeholder="Message"
            className="w-full p-2 rounded my-4 text-[#00000066]"
          >
            Message
          </textarea>
        </div>

        <input
          type="submit"
          value="Send"
          className="bg-sec self-center text-white py-2 rounded w-44 justify-self-center mt-4"
        />
      </form>
    </div>
  );
};

export default Form;
