import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './ClassifiedViewPage.css';
import DefaultLayout from '../../layout/DefaultLayout/DefaultLayout';
import TitleText from '../../components/TitleText/TitleText';
import ImageSelector from '../../components/ImageSelector/ImageSelector';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Corousel from '../../components/Corousel/Corousel';
import DOMPurify from 'dompurify'
import { BASE_URL } from '../../constants';

const ClassifiedViewPage = () => {

  const params = useParams();


  const [classified, setClassified] = useState(null)




  useEffect(() => {
    fetch(`${BASE_URL}/classifieds/${params.id}`)
      .then(response => response.json())
      .then(json => setClassified(json))
      .catch(err => console.log(err))

  }, [])

  const loading = (classified) => {
    if (classified != null) {
      return false
    } else {
      return true
    }
  }


  return (
    <DefaultLayout loading={loading(classified)}>
      {/* <img className='rounded aspect-auto max-h-[600px] object-cover' src={state.classified.image_urls[0]} alt='property'></img> */}
      {classified &&
        <>
          <Corousel images={classified.image_urls}></Corousel>

          <TitleText>{classified.title}</TitleText>

          <div className='PropertyType px-4 py-5 bg-pri rounded-md flex justify-between'>
            <div className='flex justify-center align-center'>
              <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M28.0862 13.334C28.0862 22.6673 16.0862 30.6673 16.0862 30.6673C16.0862 30.6673 4.08618 22.6673 4.08618 13.334C4.08618 10.1514 5.35046 7.09914 7.6009 4.8487C9.85134 2.59827 12.9036 1.33398 16.0862 1.33398C19.2688 1.33398 22.321 2.59827 24.5715 4.8487C26.8219 7.09914 28.0862 10.1514 28.0862 13.334Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M16.0862 17.334C18.2953 17.334 20.0862 15.5431 20.0862 13.334C20.0862 11.1248 18.2953 9.33398 16.0862 9.33398C13.877 9.33398 12.0862 11.1248 12.0862 13.334C12.0862 15.5431 13.877 17.334 16.0862 17.334Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
              <p className='text-white font-bold self-center items-center'>{classified.location}</p>
            </div>

            <div className='flex gap-2 items-baseline'>
              <p className='text-white text-3xl font-bold'>Kenya</p>
            </div>
          </div>

          <TitleText>Description</TitleText>

          <div className='text-xl  text-default-color' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(classified.desc) }}>

          </div>
        </>
      }

    </DefaultLayout>
  );
}

ClassifiedViewPage.propTypes = {};

ClassifiedViewPage.defaultProps = {};

export default ClassifiedViewPage;
