
import { Route, Routes } from "react-router-dom";
import "./App.css";
import HomePage from './pages/HomePage/HomePage';
import ClassifiedsPage from './pages/ClassifiedsPage/ClassifiedsPage';
import PropertiesPage from './pages/PropertiesPage/PropertiesPage';
import PropertyViewPage from './pages/PropertyViewPage/PropertyViewPage';
import ClassifiedViewPage from './pages/ClassifiedViewPage/ClassifiedViewPage';
import FaqPage from './pages/FAQPage/FAQPage';
import AboutPage from './pages/AboutPage/AboutPage';
import ContactPage from './pages/ContactPage/ContactPage'
import MarketPage from "./pages/MarketPage/MarketPage";
import ProductViewPage from "./pages/ProductViewPage/ProductViewPage";



function App() {
  return (
    <Routes>


      <Route path="/" element={<HomePage/>}/>
      <Route path="/properties" element={<PropertiesPage />} />
      <Route path="/properties/:id" element={<PropertyViewPage />} />
      <Route path="/classifieds" element={<ClassifiedsPage />} />
      <Route path="/classifieds/:id" element={<ClassifiedViewPage />} />
      <Route path="/markets" element={<MarketPage />} />
      <Route path="/markets/:id" element={<ProductViewPage/>} />
      <Route path="/about" element={<AboutPage></AboutPage>} />
      <Route path="/contact" element={<ContactPage></ContactPage>} />
      <Route path="/faq" element={<FaqPage />} />
      <Route path="*" />


    </Routes>

  );
}

export default App;
