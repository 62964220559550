import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './HomePage.css';
import DefaultLayout from '../../layout/DefaultLayout/DefaultLayout';
import SearchBar from '../../components/SearchBar/SearchBar';
import TitleText from '../../components/TitleText/TitleText';
import PropertyCard from '../../components/PropertyCard/PropertyCard';
import ClassifiedCard from '../../components/ClassifiedCard/ClassifiedCard';
import { useEffect } from 'react';
import ReactPaginate from 'react-paginate';
import Pagination from '../../components/PropertyPagination/PropertyPagination';
import ClassifiedPagination from '../../components/ClassifiedPagination/ClassifiedPagination';
import { BASE_URL } from '../../constants';
import Corousel from '../../components/Corousel/Corousel';
import MarketPagination from '../../components/MarketPagination/MarketPagination';
import Banner from '../../components/Banner/Banner';
import { TypeAnimation } from 'react-type-animation';
import { Fade } from 'react-slideshow-image';
import FadeIn from 'react-fade-in/lib/FadeIn';

const HomePage = () => {

  const [properties, setProperties] = useState([])
  const [classifieds, setClassifieds] = useState([])
  const [products, setProducts] = useState([])
  const [show, setShow] = useState(false)

  useEffect(() => {
    fetch(`${BASE_URL}/properties`)
      .then(response => response.json())
      .then(json => setProperties(json))
      .catch(err => console.log(err))

  }, [])


  useEffect(() => {
    fetch(`${BASE_URL}/classifieds`)
      .then(response => response.json())
      .then(json => setClassifieds(json))
      .catch(err => console.log(err))

  }, [])


  useEffect(() => {
    fetch(`${BASE_URL}/products`)
      .then(response => response.json())
      .then(json => setProducts(json))
      .catch(err => console.log(err))

  }, [])


  const loading = (properties, classifieds) => {
    // return true;
    if (properties.length > 0 && classifieds.length > 0) {

      return false
    } else {
      return true
    }

  }

  // alert(loading())




  const toggleShow = () => {
    setShow(!show)
  }

  const images = ['./home_image.png', './home2.png', './home_image.png']





  return (
    <div className='flex flex-col'>
      {!loading(properties, classifieds) &&
        <>
          <div className='mt-[20px]'>
            <div className=' flex mt-[60px]  h-[450px] md:h-[800px] xl:h-[1000px] flex-col justify-center bg-main_5 bg-opacity-10 bg-cover w-full bg-no-repeat'>
              <div className=' bg-black flex flex-1 justify-center items-center bg-opacity-50'>
              <FadeIn>
                <p className=" text-3xl md:text-5xl text-center text-white font-bold mx-6">Welcome to <span className='text-red-500'>Maskani Kenya</span></p>
                <p className='text-4xl md:text-7xl text-center text-white font-bold mx-6 my-4'>Find Your Dream House with US</p>
                <p className='text-white md:text-xl mt-4 text-center mx-6'>Maskani Kenya is the trusted resource for home buyers sellers
                 and dreamers offering the most comprehensive of for sale or rent properties and information</p>
              </FadeIn>
              </div>
            </div>
          </div>
        </>

      }

      <DefaultLayout loading={loading(properties, classifieds)}>
        {/* <Corousel></Corousel> */}
        <div
          className='w-full'
        // className=" bg-home-bg bg-no-repeat bg-cover rounded-2xl w-full h-auto  mt-8 md:h-[350px] flex flex-col justify-between lg:h-[615px]  pt-20  pb-6 px-4"
        >
          {/* <img className='w-full' src='./home_image.png' alt="home" /> */}
          {/* <Corousel images={images}></Corousel> */}
          {/* <div className='w-7/12 flex flex-col gap-6 pb-8 min-h-max'>
            <h1 className=' font-bold'><span className=' text-sec text-2xl lg:text-4xl'>Maskani </span><span className=' text-accent text-2xl lg:text-4xl'>Kenya</span></h1>
            <p className='mt-4 lg:text-lg'>Your one stop  for properties rentals,classifieds and all home solutions</p>
          </div>
          <div className='mt-4 justify-self-center'>
            <button
              type="button"
              class="inline-block  rounded-full bg-primary px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]">
              Sell With Us
            </button>
          </div> */}
        </div>

        <Banner></Banner>

        {/* <SearchBar></SearchBar> */}

        <TitleText>Recent Properties</TitleText>
        <Pagination items={properties}></Pagination>
        <TitleText>Recent Classifieds</TitleText>
        <Banner></Banner>
        <ClassifiedPagination items={classifieds}></ClassifiedPagination>
        <Banner></Banner>
        <TitleText>Markets</TitleText>
        <MarketPagination items={products}></MarketPagination>
      </DefaultLayout>

 
    </div>
  );
}

HomePage.propTypes = {};

HomePage.defaultProps = {};

export default HomePage;
