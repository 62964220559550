import React from 'react'
import Form from '../../components/ContactForm/Form'
import GetInTouch from '../../components/GetInTouchContactPage/GetInTouch'
import DefaultLayout from '../../layout/DefaultLayout/DefaultLayout';

const ContactPage = () => {
  return (
    <DefaultLayout>
      <div>
        <div>
          <h1 className="font-bold text-3xl text-center my-12 text-pri">
            Contact Us
          </h1>
        </div>
        <div className="lg:grid grid-cols-2   gap-8 ">
          <div className=" rounded">
            <Form />
          </div>
          <div className=" lg:bg-[#1F223E] rounded">
            <GetInTouch />
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
}

export default ContactPage