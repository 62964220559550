import React from 'react';
import PropTypes from 'prop-types';
import './Pagination.css';
import ReactPaginate from 'react-paginate';

const Pagination = ({items,handlePageChange,itemsPerPage=8}) => (
  <div className='my-4'>
    <ReactPaginate
        previousLabel="prev"
        nextLabel="next"
        pageCount={Math.ceil(items.length / itemsPerPage)}
        onPageChange={handlePageChange}
        containerClassName="flex flex-wrap mx-4 justify-center"
        pageClassName="rounded-full mx-1 my-2 bg-white hover:bg-gray-300 px-4 py-2 cursor-pointer"
        activeClassName="text-pri bg-pri font-bold border-2 border-pri"
        breakClassName="mx-1 my-2"
        previousClassName="rounded-full mx-1 my-2 bg-white hover:bg-gray-300  px-4 py-2 cursor-pointer"
        nextClassName="rounded-full mx-1 my-2 bg-white hover:bg-gray-300 px-4 py-2 cursor-pointer"
        disabledClassName="opacity-50 cursor-not-allowed"
        pageRangeDisplayed={1}
        marginPagesDisplayed={1}
      />

</div>
);

Pagination.propTypes = {};

Pagination.defaultProps = {};

export default Pagination;
