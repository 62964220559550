import React from 'react';
import PropTypes from 'prop-types';
import './TitleText.css';

const TitleText = ({children}) => (
  <h2 className="TitleText mx-4 text-2xl my-10 font-bold capitalize" data-testid="TitleText">
    {children}
  </h2>
);

TitleText.propTypes = {};

TitleText.defaultProps = {};

export default TitleText;
