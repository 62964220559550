import React from "react";
import PropTypes from "prop-types";
import "./ServiceCard.css";

const ServiceCard = (props) => {
  return (
    <div
      className="ServiceCard rounded-lg border dark:bg-neutral-700 shadow-lg pb-12"
      data-testid="ServiceCard"
    >
      <div>
        <img className="rounded-t-lg" src={props.image} alt="" />
      </div>
      <div className="relative">
        <div className="absolute top-0 transform -translate-y-1/2 overflow-hidden inline-block bg-pri px-6 text-white py-4 font-bold">
          {props.title}
        </div>
      </div>
      <div className="p-6 mt-4">
        <ul className="list-disc list-inside">
          {props.features.map((feature, index) => (
            <li key={index}>{feature}</li>
          ))}
        </ul>
      </div>
      <div className="flex justify-center">
        <button className="bg-sec text-white py-2 px-8 rounded">Join us</button>
      </div>
    </div>
  );
};

ServiceCard.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  features: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default ServiceCard;
