import React from 'react';
import PropTypes from 'prop-types';
import './ImageSelector.css';
import NextImageButton from '../NextImageButton/NextImageButton';

const ImageSelector = () => (

  <div className='Selector relative py-20 flex items-center justify-center w-full gap-8'>
    <div className='left'>
      <NextImageButton></NextImageButton>
    </div>
    <div className='Images grid grid-cols-3  self-center justify-self-center w-max gap-4'>
      <div>
        <img className='rounded' src='/sample.png' alt='property'></img>
      </div>

      <div>
        <img className='rounded' src='/sample.png' alt='property'></img>
      </div>

      <div>
        <img className='rounded' src='/sample.png' alt='property'></img>
      </div>

    </div>

    <div className='right right-[-8px]'>
      <NextImageButton left={false}></NextImageButton>
    </div>


  </div>
);

ImageSelector.propTypes = {};

ImageSelector.defaultProps = {};

export default ImageSelector;
