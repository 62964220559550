import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './PropertiesPage.css';
import DefaultLayout from '../../layout/DefaultLayout/DefaultLayout';
import SearchBar from '../../components/SearchBar/SearchBar';
import TitleText from '../../components/TitleText/TitleText';
import PropertiesLayout from '../../layout/PropertiesLayout/PropertiesLayout';
import PropertyCard from '../../components/PropertyCard/PropertyCard';
import PropertyPagination from '../../components/PropertyPagination/PropertyPagination';
import { BASE_URL } from '../../constants';

const PropertiesPage = () => {
  
  const [properties, setProperties] = useState(null)

 
  

  useEffect(() => {
    fetch(`${BASE_URL}/properties`)
      .then(response => response.json())
      .then(json => setProperties(json))
      .catch(err => console.log(err))

  }, [])


  const search = (searchParams)=>{
    setProperties([])
    fetch(`${BASE_URL}/products/search?q=${searchParams}&category=''`)
    .then(response => response.json())
    .then(json => setProperties(json))
    .catch(err => console.log(err))
  }



  const loading = (properties) =>{
    if(properties){
      return false
    }else{
      return true
    }
  }
  
  return(
  <DefaultLayout loading={loading(properties)}>
    <SearchBar handleSearch={search}></SearchBar>
    <TitleText>Properties</TitleText>
    {properties &&
    <PropertyPagination items={properties}></PropertyPagination>
    }
    

  </DefaultLayout>
);
}

PropertiesPage.propTypes = {};

PropertiesPage.defaultProps = {};

export default PropertiesPage;
