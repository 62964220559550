import React from 'react';
import PropTypes from 'prop-types';
import './PropertyCard.css';
import PropertyTypeTag from '../PropertyTypeTag/PropertyTypeTag';
import CardTitle from '../CardTitle/CardTitle';
import { Link } from 'react-router-dom';

const PropertyCard = ({ property }) => (

  <div className="PropertyCard rounded-md flex flex-col gap-2 shadow flex-1" data-testid="PropertyCard">
    <Link to={"/properties/"+property.id}  state={{ property: property }}>
      {/* head */}
      <div className='Header min-h-[128px] relative'>
        <div className=' absolute right-4 top-4'>
          <PropertyTypeTag tag={property.category} ></PropertyTypeTag>
        </div>
        {property.image_urls || property.main_image_url ?
          <>
            {property.main_image_url ?
              <img className='w-full  rounded-t-md object-cover h-[180px]' src={property.main_image_thumbnail_url} alt="product"></img>
              :
              <img className='w-full  rounded-t-md object-cover h-[180px]' src={property.image_thumbnails_urls[0]} alt="product"></img>
            }
          </>
          :
          <div className='w-full  rounded-t-md object-cover h-[180px]' />
        }
      </div>


      {/* body */}

      <div className='divide-y' >

        <div className='Body flex flex-col w-full gap-2 p-4'>
          <CardTitle>{property.title}</CardTitle>
          <p className='Price text-xl lg:text-2xl font-bold text-[#102C52]'><span>KSh </span>{property.price}</p>

          {/* locations */}
          <div className='Location flex flex-row justify-start gap-2 items-center'>
            <div className='h-full flex flex-col justify-center items-center'>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_111_1283)" filter="url(#filter0_d_111_1283)">
                  <path d="M17 6.66663C17 11.3333 11 15.3333 11 15.3333C11 15.3333 5 11.3333 5 6.66663C5 5.07533 5.63214 3.5492 6.75736 2.42399C7.88258 1.29877 9.4087 0.666626 11 0.666626C12.5913 0.666626 14.1174 1.29877 15.2426 2.42399C16.3679 3.5492 17 5.07533 17 6.66663Z" stroke="#EF4424" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M11 8.66663C12.1046 8.66663 13 7.7712 13 6.66663C13 5.56206 12.1046 4.66663 11 4.66663C9.89543 4.66663 9 5.56206 9 6.66663C9 7.7712 9.89543 8.66663 11 8.66663Z" stroke="#EF4424" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </g>
                <defs>
                  <filter id="filter0_d_111_1283" x="-1" y="0" width="24" height="24" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="2" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_111_1283" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_111_1283" result="shape" />
                  </filter>
                  <clipPath id="clip0_111_1283">
                    <rect width="16" height="16" fill="white" transform="translate(3)" />
                  </clipPath>
                </defs>
              </svg>
            </div>


            <p className='flex-1 text-default-color'>{property.location}</p>

            {/* location */}
          </div>
        </div>
        {/* foooter */}

        <div className='Footer p-4 justify-start flex gap-6'>
          <div className='Bedroom flex gap-2 items-center'>
            <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 1C1.55313 1 2 1.44687 2 2V10H9V5C9 4.44687 9.44687 4 10 4H17C18.6562 4 20 5.34375 20 7V14C20 14.5531 19.5531 15 19 15C18.4469 15 18 14.5531 18 14V13H11H10H2V14C2 14.5531 1.55313 15 1 15C0.446875 15 0 14.5531 0 14V2C0 1.44687 0.446875 1 1 1ZM5.5 4C6.16304 4 6.79893 4.26339 7.26777 4.73223C7.73661 5.20107 8 5.83696 8 6.5C8 7.16304 7.73661 7.79893 7.26777 8.26777C6.79893 8.73661 6.16304 9 5.5 9C4.83696 9 4.20107 8.73661 3.73223 8.26777C3.26339 7.79893 3 7.16304 3 6.5C3 5.83696 3.26339 5.20107 3.73223 4.73223C4.20107 4.26339 4.83696 4 5.5 4Z" fill="#2F80ED" />
            </svg>

            <p className='font-bold text-pri' >{property.rooms}</p>
          </div>

          <div className='Bathroom flex gap-2 items-center'>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M3 2.41563C3 2.1875 3.18438 2 3.41563 2C3.525 2 3.63125 2.04375 3.70938 2.12188L4.175 2.5875C4.0625 2.86875 4 3.17813 4 3.5C4 4.12188 4.225 4.6875 4.6 5.125C4.43438 5.4125 4.475 5.78438 4.71875 6.03125C5.0125 6.325 5.4875 6.325 5.77813 6.03125L9.03125 2.78125C9.325 2.4875 9.325 2.0125 9.03125 1.72188C8.78438 1.475 8.4125 1.4375 8.125 1.60312C7.6875 1.225 7.12188 1 6.5 1C6.17812 1 5.86875 1.0625 5.5875 1.17188L5.12188 0.70625C4.66875 0.253125 4.05312 0 3.41563 0C2.08125 0 1 1.08125 1 2.41563V8C0.446875 8 0 8.44688 0 9C0 9.55313 0.446875 10 1 10H15C15.5531 10 16 9.55313 16 9C16 8.44688 15.5531 8 15 8H3V2.41563ZM1 11V11.5C1 12.3875 1.3875 13.1875 2 13.7375V15C2 15.5531 2.44688 16 3 16C3.55313 16 4 15.5531 4 15V14.5H12V15C12 15.5531 12.4469 16 13 16C13.5531 16 14 15.5531 14 15V13.7375C14.6125 13.1875 15 12.3906 15 11.5V11H1Z" fill="#2F80ED" />
            </svg>

            <p className='font-bold text-pri'>{property.bathrooms}</p>
          </div>
        </div>

      </div>
    </Link>
  </div>

);

PropertyCard.propTypes = {};

PropertyCard.defaultProps = {};

export default PropertyCard;
