import React from 'react';
import PropTypes from 'prop-types';
import './CardTitle.css';

const CardTitle = ({children}) => (
  <p className='Title text font-bold text-default-color lg:text-xl line-clamp-1'>{children}</p>
);

CardTitle.propTypes = {};

CardTitle.defaultProps = {};

export default CardTitle;
