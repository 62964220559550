import React, { Component } from "react";
import { Slide } from "react-slideshow-image";
import "./Corousel.css";
import "react-slideshow-image/dist/styles.css";

class Corousel extends Component {
  constructor() {
    super();
    this.slideRef = React.createRef();
    this.back = this.back.bind(this);
    this.next = this.next.bind(this);
    this.state = {
      current: 0
    };
  }

  back() {
    this.slideRef.current.goBack();
  }

  next() {
    this.slideRef.current.goNext();
  }

  render() {
    const properties = {
      duration: 5000,
      autoplay: true,
      transitionDuration: 500,
      arrows: true,
      infinite: true,
      easing: "ease",
      indicators: (i) => <div className="indicator mx-2  w-full "><div>
        <img className='rounded max-h-[80px] w-full object-cover object-left' src={slideImages[i]} alt='property'></img>
      </div></div>
    };




    // const slideImages = [
    //   "https://images.unsplash.com/photo-1509721434272-b79147e0e708?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=80",
    //   "https://images.unsplash.com/photo-1506710507565-203b9f24669b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1536&q=80",
    //   "https://images.unsplash.com/photo-1536987333706-fc9adfb10d91?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=80",
    //   "https://images.unsplash.com/photo-1444525873963-75d329ef9e1b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=80"
    // ];

    const slideImages = this.props.images
    return (
      <div className="mt-10 min-h-[700px]">
        <div className="slide-container">
          <Slide ref={this.slideRef} {...properties}>
            {slideImages.map((each, index) => (
              <div key={index} className="each-slide max-h-[700px]">
                <img className="lazy object-cover w-full h-full flex  rounded" src={each} alt="sample" />
              </div>
            ))}
          </Slide>
        </div>

        {/* <div className="slide-container buttons">
          <button onClick={this.back} type="button">
            Go Back
          </button>
          <button onClick={this.next} type="button">
            Go Next
          </button>
        </div> */}
      </div>
    );
  }
}


Corousel.propTypes = {};

Corousel.defaultProps = {};

export default Corousel;
