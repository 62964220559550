import React from 'react';
import PropTypes from 'prop-types';
import './PropertyTypeTag.css';

const PropertyTypeTag = ({tag}) => (
  <div className="PropertyTypeTag rounded-full bg-accent py-1 px-4 text-white font-bold"  data-testid="PropertyTypeTag">
    {tag}
  </div>
);

PropertyTypeTag.propTypes = {};

PropertyTypeTag.defaultProps = {};

export default PropertyTypeTag;
