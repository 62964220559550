import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './MobileNav.css';
import MobileNavLink from '../MobileNavLink/MobileNavLink';

const MobileNav = () => {

  const [show, setShow] = useState(false)


  const toggleShow = () => setShow(!show)






  return (

    <div class="">

      <div class="dropdown inline-block relative">
        <button href='#' onClick={toggleShow} class="font-semibold py-2  rounded inline-flex items-center" tabIndex="5" >
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21 10H7" stroke="#2F80ED" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M21 6H3" stroke="#2F80ED" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M21 14H3" stroke="#2F80ED" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M21 18H7" stroke="#2F80ED" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        </button>
        {show &&
          <div class="bg white dropdown-menu absolute text-gray-700 pt-1 right-5 flex flex-col bg-[#f1f1f1] gap-1 rounded-s shadow-xl z-50 min-w-[200px]">
            <MobileNavLink text="Home" path="/"></MobileNavLink>

            <MobileNavLink text="Properties" path="/properties"></MobileNavLink>

            <MobileNavLink text="Classifieds" path="/classifieds"></MobileNavLink>

            <MobileNavLink text="Market" path="/markets"></MobileNavLink>


            {/* <MobileNavLink text="About" path="/about"></MobileNavLink> */}

            {/* <MobileNavLink text="Contact" path="/contact"></MobileNavLink> */}

            <div className='p-2 h-full my-4'>
            <a href='https://maskaniadmin-production.up.railway.app/'>
            <button
              type="button"
              class=" w-full whitespace-nowrap overflow-ellipsis rounded-full text-white bg-sec tx px-8 pb-3 pt-3.5 text-xs font-medium uppercase leading-normal transition duration-150 ease-in-out  focus:bg-primary-accent-100 focus:outline-none focus:ring-0 active:bg-primary-accent-200">
              Sell with Us
            </button>
            </a>
            </div>
          </div>
        }

      </div>

    </div>
  );
}

MobileNav.propTypes = {};

MobileNav.defaultProps = {};

export default MobileNav;
