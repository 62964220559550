import React from "react";
import PropTypes from "prop-types";
import "./AboutPage.css";
import DefaultLayout from "../../layout/DefaultLayout/DefaultLayout";
import AboutUsCard from "../../components/ServiceCardLayout/ServiceCardLayout";

const AboutPage = () => (
  <div className="AboutPage" data-testid="AboutPage">
    <DefaultLayout>
      <div>
        <div className="lg:flex lg:flex-row-reverse justify-between gap-6 ">
          <div className="lg:w-1/2">
            <h1 className="title-color text-center lg:text-left text-3xl font-bold text-pri my-12 ">
              About Us
            </h1>
            <div className="text-center grid gap-4 lg:text-left justify-center text-default-color">
              <p className="">
                Maskani Maskani Hi, we’re maskani kenya , it’s nice to meet you!
                As Kenya’s number 1 property portal, we’re passionate about
                helping people find their perfect homes and connecting them with
                the best local professionals who can help. In addition, we
                provide the Kenyan Real Estate Industry with an unparalleled
                brand-building and marketing platform, with powerful
                opportunities to connect with home-buyers and renters searching
                for property online.
              </p>

              <p className=" ">
                Maskani Maskani Hi, we’re maskani kenya , it’s nice to meet you!
                As Kenya’s number 1 property portal, we’re passionate about
                helping people find their perfect homes and connecting them with
                the best local professionals who can help. In addition, we
                provide the Kenyan Real Estate Industry with an unparalleled
                brand-building and marketing platform, with powerful
                opportunities to connect with home-buyers and renters searching
                for property online.
              </p>

              <div className="lg:mt-12 justify-self-center">
                <button className="bg-pri text-white w-32 lg:w-44   rounded-full py-2 px-8">
                  Join Us
                </button>
              </div>
            </div>
          </div>
          <div className="lg:w-1/2  mt-12 rounded bg-about-banner bg-no-repeat bg-cover">
          
          </div>
        </div>
        <div>
          <div className="">
            <h2 className="font-bold text-xl text-pri mt-12 text-center">
              Why Maskani
            </h2>
            <p className="text-default-color text-center">
              Advantages of Maskani
            </p>
          </div>
          <div>
            <AboutUsCard />
          </div>
        </div>
      </div>
    </DefaultLayout>
  </div>
);

AboutPage.propTypes = {};

AboutPage.defaultProps = {};

export default AboutPage;
